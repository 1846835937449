<template>
  <div class="container mt-5">
   <div class="row ">
     <img src="./iheader.png" alt="header" width="100%" />
   </div>
   <div class="row bortr mt-2">
     <div class="col-sm-12">
       <h1 class="text-center">Tax Invoice / فاتورة ضريبية</h1>
     </div>
   </div>
   <div class="row bortr">
     <div class="col-sm-6 p-0">
       <table class="table table-sm m-0">
         <tbody>
           <tr class="no-border">
             <td>Invoice No:</td>
             <td>{{ invoice.invoice_id }}</td>
             <td class="text-right">رقم الفاتورة</td>
           </tr>
           <tr class="no-border">
             <td>Invoice Issue Date:</td>
             <td>{{ invoice.created_at | moment("DD MMM YYYY") }}</td>
             <td class="text-right">تاريخ الفاتورة</td>
           </tr>
           
           <tr class="no-border">
             <td>Reference No</td>
             <td>{{ invoice.ref_invoice }}</td>
             <td class="text-right">رقم المرجع</td>
           </tr>
           <tr>
            <td>Contract No.</td>
             <td>{{ invoice.contract_no }}</td>
             <td class="text-right">رقم العقد</td>
           </tr>
         </tbody>
       </table>
     </div>
     <div class="col-sm-6">
       <div class="mt-4 float-right">
         <vue-qrcode
           :value="invoice.qrcode"
           :options="{ width: 200 }"
         ></vue-qrcode>
       </div>
     </div>
   </div>
   <div class="row">
     <table class="table table-sm m-0">
       <tbody>
         <tr class="no-border top-boder">
           <td class="text-left">Seller</td>
           <td class="text-center"></td>
           <td class="text-right td-3">: البائع</td>
           <td class="text-left">Buyer</td>
           <td class="text-center"></td>
           <td class="text-right">:المشتري</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">Company Name</td>
           <td class="text-center fa-nb">
             شركة ابناء عبدالرحمن على البصيلى
             <br />
             AbdulRahman Ali AlBusaili Sons Co
           </td>
           <td class="text-right td-3">اسم الشركة</td>
           <td class="text-left">Company Name</td>
           <td class="text-center fa-nb">
             شركة اعمال سديم للتجارة (شركة شخص واحد)
             <br />
             Sadeem Business Trading Company (one person company)
           </td>
           <td class="text-right">اسم الشركة</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">VAT ID</td>
           <td class="text-center fa-nb">300047805100003</td>
           <td class="text-right td-3">رقم شهادة ضريبة القيمة المضافة</td>
           <td class="text-left">VAT ID</td>
           <td class="text-center fa-nb">311332964600003</td>
           <td class="text-right">رقم شهادة ضريبة القيمة المضافة</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">Building No</td>
           <td class="text-center fa-nb">7529</td>
           <td class="text-right td-3">رقم المبني</td>
           <td class="text-left">Building No</td>
           <td class="text-center fa-nb">-</td>
           <td class="text-right">رقم المبني</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">Street Name</td>
           <td class="text-center fa-nb">
             Salah Aldin Al-Ayoubi/صلاح الدين الايوبي
           </td>
           <td class="text-right td-3">إسم الشارع</td>
           <td class="text-left">Street Name</td>
           <td class="text-center fa-nb">-</td>
           <td class="text-right">إسم الشارع</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">District</td>
           <td class="text-center fa-nb">Al-Zahraa /الزهراء</td>
           <td class="text-right td-3">الحي</td>
           <td class="text-left">District</td>
           <td class="text-center fa-nb">-</td>
           <td class="text-right">الحي</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">City</td>
           <td class="text-center fa-nb">Riyadh /الرياض</td>
           <td class="text-right td-3">المدينة</td>
           <td class="text-left">City</td>
           <td class="text-center fa-nb">Riyadh /الرياض </td>
           <td class="text-right">المدينة</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">Country</td>
           <td class="text-center fa-nb">KSA /السعودية</td>
           <td class="text-right td-3">الدولة</td>
           <td class="text-left">Country</td>
           <td class="text-center fa-nb">KSA /السعودية</td>
           <td class="text-right">الدولة</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">Postal Code</td>
           <td class="text-center fa-nb">12811</td>
           <td class="text-right td-3">الرمزي البريدي</td>
           <td class="text-left">Postal Code</td>
           <td class="text-center fa-nb">-</td>
           <td class="text-right">الرمزي البريدي</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">Additional No.</td>
           <td class="text-center fa-nb">2071</td>
           <td class="text-right td-3">الرقم الأضافي</td>
           <td class="text-left">Additional No.</td>
           <td class="text-center fa-nb">-</td>
           <td class="text-right">الرقم الأضافي</td>
         </tr>
         <tr class="no-border">
           <td class="text-left">CR Number</td>
           <td class="text-center fa-nb">1010152299</td>
           <td class="text-right td-3">رقم السجل التجاري</td>
           <td class="text-left">CR Number.</td>
           <td class="text-center fa-nb">1010809359</td>
           <td class="text-right">رقم السجل التجاري</td>
         </tr>
         <tr class="no-border top-boder">
           <td class="text-left" colspan="3">Line Items:</td>
           <td class="text-right" colspan="3">البند</td>
         </tr>
       </tbody>
     </table>
   </div>
   <div class="row">
     <table class="table table-sm m-0">
       <tbody>
         <tr class="row-tr">
           <td class="text-center">Description</td>
           <td class="text-center">الوصف</td>
           <td class="text-center">Unit Price / سعر الوحدة</td>
           <td class="text-center">Quantity/الكمية</td>
           <td class="text-center">Taxable Amount/القيمة</td>
           <td class="text-center">Discount/الخصم</td>
           <td class="text-center">Tax Rate/نسبة الضريبة</td>
           <td class="text-center">Tax Amount/قيمة الضريبة</td>
           <td class="text-center">
             Item Subtotal Including VAT/ المبلغ بعد الضريبة
           </td>
         </tr>
         <tr class="row-tr" v-for="(value, index) in items" :key="index">
           <td class="text-left fa-nb">
             {{ value.item }}
           </td>
           <td class="text-left fa-nb">
             {{ value.item_arabic }}
           </td>
           <td class="text-center fa-nb">{{ comma(value.price) }}</td>
           <td class="text-center fa-nb">{{ value.quantity }}</td>
           <td class="text-center fa-nb">{{ comma(value.amount) }}</td>
           <td class="text-center fa-nb">0</td>
           <td class="text-center fa-nb">15%</td>
           <td class="text-center fa-nb">{{ comma(value.amount * 0.15) }}</td>
           <td class="text-center fa-nb">{{ sum(value.amount) }}</td>
         </tr>
       </tbody>
     </table>
   </div>
   <div class="row bortr mt-4">
     <div class="col-sm-6 p-0">
       <p class="text-left fa-bold m-1 f-14">Total Amount</p>
     </div>
     <div class="col-sm-6 p-0">
       <p class="text-right fa-bold m-1 f-14">المبلغ الإجمالي</p>
     </div>
   </div>
   <div class="row bortr">
     <div class="col-sm-6 p-0"></div>
     <div class="col-sm-6 p-0">
       <table class="table table-sm mr-0">
         <tbody>
           <tr class="no-border">
             <td class="text-left">Sub Total</td>
             <td class="text-right">الإجمالي قبل الضريبة</td>
             <td class="text-right">{{ invoice_total_amount }}</td>
           </tr>
           <tr class="no-border">
             <td class="text-left">Discount</td>
             <td class="text-right">الخصم</td>
             <td class="text-right">0</td>
           </tr>
           <tr class="no-border">
             <td class="text-left">Total Taxable Amount (Excluding VAT)</td>
             <td class="text-right">الاجمالي قبل الضريبة</td>
             <td class="text-right">{{ invoice_total_amount }}</td>
           </tr>
           <tr class="no-border">
             <td class="text-left">Total VAT</td>
             <td class="text-right">قيمة الضريبة</td>
             <td class="text-right">{{ invoice_vat_amount }}</td>
           </tr>
           <tr class="no-border">
             <td class="text-left">Gross Amount</td>
             <td class="text-right">القيمة</td>
             <td class="text-right">{{ invoice_gross_amount }}</td>
           </tr>
           <tr class="no-border" v-if="invoice.no_retention == 'Yes'">
             <td class="text-left">Total Amount Due</td>
             <td class="text-right">المبلغ الإجمالي</td>
             <td class="text-right">{{ invoice_net_amount }}</td>
           </tr>
         </tbody>
       </table>
     </div>
   </div>
   <div class="row">
     <table class="table table-sm no-border">
       <tbody>
         <tr>
           <td class="text-left">
             Amount in Words: {{ invoice.amount_words }}
           </td>
           <td class="text-right">
             المبلغ بالكلمات:{{ invoice.amount_words_ar }}
           </td>
         </tr>
       </tbody>
     </table>
   </div>
   <div class="row">
     <img src="./ifooter.png" alt="header" width="100%">
   </div>
 </div>
</template>
<script>
const moment = require("moment");
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");
const converter = require("number-to-words");
export default {
 name: "SalesInvoice",
 components: {
   VueQrcode: VueQrcode,
 },
 data: function () {
   return {
     selected_expat: "progress",
     option_expat: [
       {
         text: "Progress",
         value: "progress",
       },
       {
         text: "Retention",
         value: "retention",
       },
       {
         text: "Other",
         value: "other",
       },
     ],
     selected_approval: "yes",
     option_approval: [
       {
         text: "Yes",
         value: "yes",
       },
       {
         text: "No",
         value: "no",
       },
     ],
     invoice: {},
     items: [],
     invoice_total_amount: 0,
     invoice_vat_amount: 0,
     invoice_gross_amount: 0,
     invoice_rentention_amount: 0,
     invoice_net_amount: 0,
     // amount_words: "",
     hc_address: "Riyadh",
   };
 },
 mounted() {
   this.retrieve(this.$route.params.id);
 },
 methods: {
   trigger(t) {
     alert(t);
   },
   toggle() {
     this.show = !this.show;
   },
   comma(data) {
     //  return data
     return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
       minimumFractionDigits: 2,
     });
   },
   sum(n1) {
     let vat = Number(n1) * 0.15;
     let total = Number(n1) + vat;
     return this.comma(total);
   },
   retrieve(id) {
     HRMService.getOrderItems(id)
       .then((response) => {
         this.invoice = response.data["sales"][0];
         this.items = response.data["items"];
         this.invoice_total_amount = this.comma(this.invoice.total_amount);
         this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
         this.invoice_gross_amount = this.comma(this.invoice.gross_amount);
         this.invoice_rentention_amount = this.comma(
           this.invoice.retention_amount
         );
         this.invoice_net_amount = this.comma(this.invoice.net_amount);
       })
       .catch((e) => {
         console.log(e);
       });
   },
 },
};
</script>
<style scoped>
.word {
 text-transform: capitalize;
}
.fa-bold {
 font-weight: bold;
}
.float-center {
 text-align: center;
}
.table tr td {
 font-size: 14px !important;
 font-weight: bold;
 margin: 0;
 padding: 0px !important;
 padding-left: 4px !important;
 padding-right: 4px !important;
}
.bortr {
 border: black solid 1px;
}
.no-border {
 border-left: black solid 1px;
 border-right: black solid 1px;
}
.right-border {
 border-right: black solid 1px !important;
}
.top-boder {
 border-top: solid 1px black !important;
}
.bottom-boder {
 border-bottom: solid 1px black !important;
}
.table td {
 border: solid 1px black;
}
.td-3 {
 border-right: solid 1px black !important;
}
.row-tr td {
 border: black solid 1px;
}
.fa-nb {
 font-weight: 300 !important;
}
.f-14 {
 font-size: 14px !important;
}
.image {
 width: 100px;
 align-self: center;
}
.header-arabic {
 align-self: flex-end;
 font-size: 15px;
 width: 300px;
 border: 1px solid #000;
}
.al-right {
 align-self: flex-end;
}
.df {
 display: flex;
 align-items: center;
}
.center {
 display: flex;
 align-items: center;
 justify-content: center;
}
.text-sm {
 font-size: 10px;
}
.text-md {
 font-size: 14px;
}
</style>