import { render, staticRenderFns } from "./sales-invoice-sadeem-business.vue?vue&type=template&id=efad3ecc&scoped=true"
import script from "./sales-invoice-sadeem-business.vue?vue&type=script&lang=js"
export * from "./sales-invoice-sadeem-business.vue?vue&type=script&lang=js"
import style0 from "./sales-invoice-sadeem-business.vue?vue&type=style&index=0&id=efad3ecc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efad3ecc",
  null
  
)

export default component.exports